import router from '@/router'
import db from '@/firebase/init'
import firebase from 'firebase'

/*------------------------------------------------------------------------------
 * STATE
 *----------------------------------------------------------------------------*/

const initialState = () => {
  return {
    data: {},
    status: {
      error: null,
      validating: false,
      resetSent: null,
    }
  }
}

const state = initialState()

/*------------------------------------------------------------------------------
 * MUTATIONS
 *----------------------------------------------------------------------------*/

const mutations = {
  setValidatingState(state, bol) {
    state.status.validating = bol
  },

  setError(state, message) {
    state.status.error = message
  },

  clearPassword(state) {
    state.data.password = ''
  },

  setResetStatus(state, message) {
    state.status.resetSent = message
  },

  resetState(state) {
    Object.assign(state, initialState())
  }
}

/*------------------------------------------------------------------------------
 * ACTIONS
 *----------------------------------------------------------------------------*/

const actions = {
  /*------------------------------------------------------------------------------
   * LOGIN USER
   *----------------------------------------------------------------------------*/
  loginUser({ commit, state, dispatch, rootState }) {
    commit('setValidatingState', true)
    if (state.status.error) commit('setError', null)

    firebase.auth().signInWithEmailAndPassword(state.data.email, state.data.password)
    .then(() => {
      firebase.auth().onAuthStateChanged(() => {
        let user = firebase.auth().currentUser

        if (user) {
          db.collection('logins').add({
            user: user.uid,
            date: Date.now()
          })
          .then(() => {
            if (rootState.user.user) {
              if (['dietitian', 'nutritionist', 'naturopath'].includes(rootState.user.user.role)) router.push({ name: 'Recipes' })
              if (['personal_trainer'].includes(rootState.user.user.role)) router.push({ name: 'MealPlans' })
              else router.push({ name: 'Home' })
            } else {
              Promise.resolve(dispatch('user/getUserData', {}, { root: true }))
              .then(doc => {
                const user = doc.data()
                if (['dietitian', 'nutritionist', 'naturopath'].includes(user.role)) router.push({ name: 'Recipes' })
                if (['personal_trainer'].includes(user.role)) router.push({ name: 'MealPlans' })
                else router.push({ name: 'Home' })
              })
            }

            // Check if on mobile
            if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)){
              dispatch('user/toggleDrawer', {drawer: false}, {root: true})
            }
            
            dispatch('showSuccess', 'You are successfully logged in.', { root: true })
          })
        }
      })
    })
    .catch(error => {
      commit('setError', error.message)
      commit('setValidatingState', false)
      commit('clearPassword')
    })
  },

  /*------------------------------------------------------------------------------
   * RESET PASSWORD
   *----------------------------------------------------------------------------*/
  resetPassword({ commit, state }) {
    console.log("Password reset initiated for email:", state.data.email);
    
    // Clear previous errors
    if (state.status.error) {
      console.log("Clearing previous errors");
      commit('setError', null);
    }
    
    // Validate email
    if (!state.data.email || !state.data.email.trim()) {
      console.error("Email validation failed: Email is empty or only whitespace");
      commit('setError', 'Email address is required');
      return Promise.reject(new Error('Email address is required'));
    }
    
    const trimmedEmail = state.data.email.trim();
    console.log("Validated email:", trimmedEmail);
    
    // Set loading state
    commit('setValidatingState', true);
    console.log("Set validating state to true");
    
    // Send reset email
    console.log("Sending password reset email to Firebase");
    return firebase.auth().sendPasswordResetEmail(trimmedEmail)
      .then(() => {
        console.log("Password reset email sent successfully");
        commit('setResetStatus', 'Reset email sent. Please check your inbox or spam folder.');
        commit('setValidatingState', false);
        return true;
      })
      .catch(error => {
        console.error("Error sending password reset email:", error);
        console.error("Error code:", error.code);
        console.error("Error message:", error.message);
        
        let errorMessage = 'Failed to send reset email';
        
        // More specific error messages based on Firebase error codes
        if (error.code === 'auth/user-not-found') {
          errorMessage = 'No account found with this email address';
        } else if (error.code === 'auth/invalid-email') {
          errorMessage = 'The email address is not valid';
        } else if (error.code === 'auth/too-many-requests') {
          errorMessage = 'Too many requests. Please try again later';
        }
        
        commit('setError', errorMessage);
        commit('setValidatingState', false);
        return Promise.reject(error);
      });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
